/* eslint-disable no-use-before-define */
import { TaskSummaryDto } from '@cloudmed/resolution-services-api-client';
import {
  GetMyTasksParams,
  GetMyTasksParamsOriginal,
  GetTasksParams,
  GetTasksParamsOriginal,
} from '../models/tasks.model';

export function convertTaskDtoTo<T extends { patientName: string }>(
  task: TaskSummaryDto
): T {
  return {
    ...task,
    patientName: getPatientName(task),
  } as T;
}

function getPatientName(task: TaskSummaryDto): string {
  return `${task.patientLastName} ${task.patientFirstName}`;
}

export function convertToGetTasksParams(
  params: GetTasksParams
): GetTasksParamsOriginal {
  return [
    params.limit,
    params.continuationToken,
    params.status,
    params.assignedTo,
    params.completedBy,
    params.sort,
    params.completedSince,
    // new parameter "workflowInstanceId" was added to API.
    // But currently there is no other sources where UI
    // can get this "workflowInstanceId". So, skips it for
    // now by setting "null".
    null,
  ];
}

export function convertToGetMyTasksParams(
  params: GetMyTasksParams
): GetMyTasksParamsOriginal {
  return [
    params.limit,
    params.includeUnassigned,
    params.continuationToken,
    params.sort,
    params.status,
  ];
}
